<template>
  <a-modal
      v-drag-modal
      title="图片预览"
      :visible="previewVisible"
      destroyOnClose
      @cancel="previewVisible = false"
  >
    <img ref="img" alt="example" style="width: 100%" :src="previewImage"/>
    <image-edit v-show="editMode"
                ref="imgEdit"
                :dialog-img="imgEditShow"
                :counter.sync="counter"
                :img-path="imgPath "
                @closeDialog="closeDialog"
    />

    <div slot="footer" v-if="!isCover">
      <div style="display: flex">
        <a-button type="default" @click="changeImage('prev')" :disabled="currentIndex<=0">&lt; 上一个</a-button>
        <div class="flex_1">&nbsp;</div>
        <a-button type="primary" @click="imgUrlEdit()">修改图片</a-button>
        <div class="flex_1">&nbsp;</div>
        <a-button type="default" @click="changeImage('next')" :disabled="currentIndex==imageList.length - 1">下一个 &gt;
        </a-button>
      </div>
    </div>
  </a-modal>
</template>

<script>
import ImageEdit from '@/components/imageEdit'

export default {
  name: "ImagePreviewModal",
  components: {
    ImageEdit
  },
  data() {
    return {
      previewVisible: false,
      previewImage: "",
      editMode: false,
      imgEditShow: false, // 图片编辑框
      imgPath: '',//  图片编辑源
      counter: 0,
      record: undefined,
      currentIndex: 0,
      imageList: [],
      isCover:false
    }
  },
  methods: {
    preview(imgSrc, record, imageList,type) {
      this.previewImage = imgSrc;
      this.previewVisible = true
      this.record = record
      this.imageList = imageList || []
      this.isCover = type=='cover'
      this.currentIndex = this.imageList.findIndex(v => (v.url || (v.response && v.response.data) || v.preview || v.thumbUrl) == imgSrc)
      // this.$nextTick( this.imgUrlEdit)
    },
    changeImage(direction) {
      if (direction === 'prev') {
        if (this.currentIndex == 0) {
          return;
        }
        this.currentIndex--;
      } else {
        if (this.currentIndex >= this.imageList.length - 1) {
          return;
        }
        this.currentIndex++;
      }
      let v = this.imageList[this.currentIndex];
      this.previewImage = v.url || (v.response && v.response.data) || v.preview || v.thumbUrl;
    },
    closeDialog(newImgPath) {
      this.imgEditShow = false
      this.editMode = false;
      if (newImgPath) {
        this.$postJson('post/updateOneImg', {
          img: this.previewImage,
          newImg: newImgPath,
          postId: this.record.id
        }).then(res => {
          this.previewImage = newImgPath
          this.$message.success('图片编辑更新成功');
          this.previewVisible = false
          this.$emit("updateSuccess")
        })

        //修改数据
      }
    },
    imgUrlEdit() {
      if (!this.previewImage.toLowerCase().startsWith("http")) {
        // this.$message.warning("请先保存后再编辑")
        return
      }
      this.editMode = true;
      this.imgEditShow = true
      this.counter++;
      if (this.previewImage.indexOf('?') < 0) {
        this.imgPath = this.previewImage + '?counter=' + this.counter
      } else {
        this.imgPath = this.previewImage + '&counter=' + this.counter
      }
      this.$refs.imgEdit.resetPath(this.imgPath)
    }
  }
}
</script>

<style scoped lang="less">

::v-deep {
  .ant-modal-body {
    max-height: 600px;
    overflow: auto
  }

}
</style>