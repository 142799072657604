<template>
  <div>
    <el-dialog
        title="修改图片"
        :visible.sync="dialogImg"
        :close-on-click-modal="false"
        top="5vh"
        width="800px"
        custom-class="m-image-dialog"
        append-to-body
        :modal-append-to-body="false"
        :destroy-on-close="true"
        :before-close="handleClose"
    >
      <div class="button-group">
        <div class="button-item" @click="rotateHandle(1)">左旋90</div>
        <div class="button-item" @click="rotateHandle(2)">右旋90</div>
        <div class="button-item" @click="cropHandle">{{ indexTab == 1 ? '裁 剪' : '取消裁剪' }}</div>
        <div class="button-item" @click="mosaicHandle">{{ !isMosaic ? '打 码' : '取消打码' }}</div>
        <div class="button-item" @click="saveHandle">保 存</div>
      </div>
      <div style="max-height:600px;max-width:800px;overflow:auto;padding-top:20px;">
        <div class="edit-img" :style="{width:imgInfo.width+'px',height:imgInfo.height+'px'}">
          <vueCropper
              ref="cropper"
              :img="editImgUrl"
              :info="false"
              :output-type="'png'"
              :info-true="true"
              :can-move="true"
              :can-scale="true"
              :can-move-box="true"
              :auto-crop-width="400"
              :auto-crop-height="400"
              :mode="'cover'"
              :center-box="true"
              :enlarge="1"
              :full="true"
              :max-img-size="200000"
              @imgLoad="imgLoadCrop"
          />
          <div v-if="isMosaic" class="tumo-img" :style="{width:imgInfo.width+'px',height:imgInfo.height+'px'}">
            <canvas id="canvas"/>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>

import {VueCropper} from 'vue-cropper'
import Mosaic from 'image-mosaic'
// import {upLoad1} from '@/api/files-management'

export default {
  components: {
    VueCropper
  },
  props: {
    dialogImg: {
      type: Boolean,
      default: () => false
    },
    imgPath: {
      type: String,
      default: () => ''
    },
  },
  data() {
    return {
      editImgUrl: '', // 编辑后的图片
      indexTab: 1, // 1是裁剪，2是取消裁剪
      isMosaic: false, // 是否打码
      isLoading: true,
      imgInfo: {
        width: 800, height: 700
      }
    }
  },
  watch: {
    imgPath(val) {
      console.log("watch imgPath=" + val)
      let docProtocol = window.document.location.href.split(':')[0];
      let valProtocol = val.split(':')[0];
      if (docProtocol != valProtocol) {
        val = val.replace(valProtocol, docProtocol)
      }
      this.editImgUrl = val
      this.getImgInfo();
    },
  },
  methods: {
    resetPath(path) {
      // this.editImgUrl = path
      // debugger
      // this.getImgInfo();
    },
    imgLoadCrop() {
      this.isLoading = false
    },
    async getImgInfo() {
      const _this = this;
      let img = new Image()
      img.src = this.editImgUrl

      function callFunction(img) {
        return new Promise((resolve, reject) => {
          img.onload = () => {
            //here you all code goes
            console.log('called first');
            _this.imgInfo.width = img.width;
            _this.imgInfo.height = img.height;

            resolve(true)
          }
        })
      }

      await callFunction(img)
    },
    // 裁剪
    cropHandle() {
      if (this.isMosaic) {
        this.$message.warning('请先退出打码模式1',)
        return
      }
      if (this.indexTab === 1) {
        this.indexTab = 2
        setTimeout(() => {
          this.$refs.cropper.goAutoCrop()
        }, 0)
      } else {
        this.$refs.cropper.clearCrop()
        this.indexTab = 1
      }
    },
    // 旋转
    rotateHandle(val) {
      if (this.isMosaic) {
        this.$message.warning('请先退出打码模式')
        return
      }
      this.$refs.cropper.getCropData((data) => {
        this.editImgUrl = data
        this.$refs.cropper.clearCrop()
        setTimeout(() => {
          if (val === 1) this.$refs.cropper.rotateLeft()
          else this.$refs.cropper.rotateRight()
        }, 0)
      })
    },
    // 马赛克
    mosaicHandle() {
      this.isMosaic = !this.isMosaic
      if (this.isMosaic) {
        this.$refs.cropper.getCropData((data) => {
          this.editImgUrl = data
          this.indexTab = 1
          this.$refs.cropper.clearCrop()
          setTimeout(() => {
            this.initMosaic()
          }, 0)
        })
      }
    },
    handleClose() {
      this.$refs.cropper.clearCrop()
      this.editImgUrl = ''
      this.indexTab = 1
      this.isMosaic = false
      this.$emit('closeDialog')
    },
    drawImageToCanvas(imageUrl) {
      const canvas = document.querySelector('#canvas')
      const ctx = canvas.getContext('2d')
      const _this = this;
      return new Promise((resolve, reject) => {
        const image = new Image()
        image.crossOrigin = 'Annoymous'
        image.onload = function () {
          const w = image.width
          const h = image.height
          const yw = document.body.clientWidth * 0.7
          const yh = h;//733;
          if ((w / yw) >= (h / yh)) {
            image.width = yw
            image.height = (yw / w) * h
          } else {
            image.height = yh
            image.width = (yh / h) * w
          }
          canvas.width = image.width
          canvas.height = image.height
          ctx.drawImage(this, 0, 0, image.width, image.height)
          resolve(ctx)
        }
        image.src = imageUrl
      })
    },
    initMosaic() {
      this.drawImageToCanvas(this.editImgUrl).then((ctx) => {
        const mosaic = new Mosaic(ctx)
        const MouseEvents = {
          init() {
            mosaic.context.canvas.addEventListener(
                'mousedown',
                MouseEvents.mousedown
            )
          },
          mousedown() {
            mosaic.context.canvas.addEventListener(
                'mousemove',
                MouseEvents.mousemove
            )
            document.addEventListener('mouseup', MouseEvents.mouseup)
          },
          mousemove(e) {
            if (e.shiftKey) {
              mosaic.eraseTileByPoint(e.layerX, e.layerY)
              return
            }
            mosaic.drawTileByPoint(e.layerX, e.layerY)
          },
          mouseup() {
            mosaic.context.canvas.removeEventListener(
                'mousemove',
                MouseEvents.mousemove
            )
            document.removeEventListener('mouseup', MouseEvents.mouseup)
          }
        }
        MouseEvents.init()
      })
    },
    saveHandle() {
      if (this.isMosaic) {
        var dataURL = document.querySelector('#canvas').toDataURL('image/png')
        this.editImgUrl = dataURL
        setTimeout(() => {
          this.uploadImg()
        }, 1000)
      } else {
        this.uploadImg()
      }
    },
    // 上传图片
    uploadImg() {
      const formData = new FormData()
      const _this = this;
      const lastDot = this.imgPath.lastIndexOf('.');
      const ext = this.imgPath.substring(lastDot).split('?')[0];
      const relativepath = this.imgPath.substring(0, lastDot)

      this.$refs.cropper.getCropBlob(data => {
        formData.append('file', data, relativepath + '-mos' + Math.floor(Math.random() * 100) + ext)
        _this.$upload("upload/uploadImage", formData)
            .then(res => {
              if (res.code == 0) {
                this.$message.success('图片已编辑')
              } else {
                this.$message.warning(res.message)
              }
              this.$refs.cropper.clearCrop()
              this.editImgUrl = ''
              this.indexTab = 1
              this.isMosaic = false
              this.$emit('closeDialog', res.data)
            })
      })
    }
  }
}
</script>

<style lang="less" scoped>
.m-image-dialog {
}

.edit-img {
  position: relative;
  overflow: auto;
  margin-top: 10px;
}

.tumo-img {
  position: absolute;
  top: 10px;
  left: 0;
  border-radius: 2px;
  overflow: auto;
}

.button-group {
  position: absolute;
  z-index: 2;
  top: 60px;
  display: flex;

  .button-item {
    width: 100px;
    height: 38px;
    border-radius: 4px;
    text-align: center;
    line-height: 38px;
    background-color: #1890ff;
    color: #FFFFFF;
    margin-left: 10px;
    cursor: pointer;
  }
}

::v-deep {
  .cropper-modal {
    background: rgba(0, 0, 0, .4) !important;
  }

  .el-dialog__body {
    padding-top: 60px;
  }
}
</style>